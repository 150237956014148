<template>
    <!-- Slider -->
    <div class="all container-fluid px-lg-4 py-lg-2 mt-lg-4">
        <div class="respo productsSlider mr-custom ml-custom mt-4">
            <h3 class=" pt-5 sub-header">{{subHeader}}</h3>
            <div :class="'swiper-'+swiperRef+'-button-next swiper-button-next'" @click="nextSlide"></div>
            <div :class="'swiper-'+swiperRef+'-button-prev swiper-button-prev'"></div>
            <swiper
                :ref="swiperRef"
                :slides-per-view="1"
                :space-between="10"
                :navigation="controls"
                :breakpoints="slider_number"
                class="mySwiper"
                @swiper="onSwiper"
                @slideChange="onSlideChange"
            >
                <swiper-slide v-for="product in productsList" :key="product.id" data-aos="flip-right" data-aos-duration="1200">
                    <ProductCard :product="product"/>
                </swiper-slide>
            </swiper>
        </div>
    </div>
</template>

<script>
// Import Swiper Vue.js components
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';

// Import Swiper styles
import 'swiper/swiper-bundle.css';


// import Swiper core and required modules
import SwiperCore, {
    Navigation
} from 'swiper';

// install Swiper modules
SwiperCore.use([Navigation]);


import ProductCard from "@/components/ProductCard";
import {mapGetters} from "vuex";
import {getProducts} from "../../../services/products";

export default {
    name: "ProductSlider",
    props:['subHeader','swiperRef','productsList','toggleProduct'],

    components: {
        ProductCard,
        Swiper,
        SwiperSlide,
    },
    computed: {
        // map `this.user` to `this.$store.getters.user`
        ...mapGetters({
            currency: "getCurrency"
        }),

    },
    setup() {
        const onSwiper = () => {
            // console.log(swiper);
        };
        const onSlideChange = () => {
            // console.log('slide change');
        };
        return {
            onSwiper,
            onSlideChange,
        };
    },

    data: function () {
        return {
            controls: {
                nextEl: `.swiper-${this.swiperRef}-button-next`,
                prevEl: `.swiper-${this.swiperRef}-button-prev`,
            },
            products: [],
            more_sales: [],
            slider_number: {
                "640": {
                    "slidesPerView": 2,
                    "spaceBetween": 20
                },
                "768": {
                    "slidesPerView": 3,
                    "spaceBetween": 40
                },
                "1024": {
                    "slidesPerView": 6,
                    "spaceBetween": 20
                }
            }
        }
    },


}
</script>

<style>
.productsSlider {
    position: relative;
    padding-bottom: 3.5rem;
}

.productsSlider .swiper {
    cursor: pointer;
}

.productsSlider .swiper-button-prev,
.productsSlider .swiper-button-next,
.productsSlider .swiper-rtl .swiper-button-next {
    width: 38px;
    height: 38px;
    background-color: var(--primary-color);
    border-radius: 50%;
}

.productsSlider .swiper-button-prev:after,
.productsSlider .swiper-button-next:after {
    font-size: 1rem;
    color: #fff;
}

@media (min-width: 1250px) {
    .swiper-button-prev, .swiper-rtl .swiper-button-next {
        left: -3rem;
    }

    .swiper-button-next, .swiper-rtl .swiper-button-prev {
        right:  -3rem;
    }
}
</style>